<template>
  <cui-acl  :roles="['HRsettings']" redirect>
    <h4>HR Instillinger</h4>
    <div class="card">
    <div class="card-body">
      <div class="kit__utils__heading">
        <h5>
          <span class="mr-3">Kontrakter</span>
          <a-button style="float: right;" @click="$router.push({ path: `/hr/settings/contracts/new` })">
            Ny standard kontrakt
          </a-button>
        </h5>
      </div>
      <a-tabs default-active-key="1">
        <a-tab-pane :key="i+1" :tab="company.name" v-for="(company, i) in companies">
          <a-collapse accordion>
            <a-collapse-panel :key="i" :header="project.title" v-for="(project, i) in projects.filter(el => el.company === company.id)">
              <list :list="contracts.filter(el => el.project === project.id)" target="/hr/settings/contracts/edit/"/>
            </a-collapse-panel>
          </a-collapse>
        </a-tab-pane>
      </a-tabs>
      <!-- <a-table
          :columns="contractColumns"
          :data-source="contracts"
          :pagination="{ pageSize: 50 }"
          >
          <a slot="name" slot-scope="text, record" @click="editContract(record.id)">{{ text }}</a>
        </a-table> -->
    </div>
    </div>
    <div class="card">
    <div class="card-body">
      <h5>
        <span class="mr-3">Standard dokumenter</span>
        <!-- <a-button style="float: right;">
           Ny Standard dokument
        </a-button> -->
      </h5>
      <a-collapse accordion>
        <a-collapse-panel :key="i" :header="document.title" v-for="(document, i) in documents">
          <p>yo</p>
        </a-collapse-panel>
      </a-collapse>
      <!-- Type dokumenter:
        * Advarsel
        * Attest
        * Oppsigelse
        * Avskjed
      -->
    </div>
  </div>
  <div v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
    <div class="card">
      <div class="card-body">
        <h5>
          <span class="mr-3">Endre Variabler</span>
          <div>
            <button @click="testfn">change variable</button>
          </div>
        </h5>
      </div>
    </div>
  </div>
  </cui-acl>
</template>

<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import list from '@/components/custom/visual/lists/List2'

import { firestore } from '@/services/firebase'

export default {
  components: {
    cuiAcl,
    list,
  },
  data() {
    return {
      companies: [],
      projects: [],
      contracts: [],
      documents: [],
      contractColumns: [
        {
          title: 'Kontrakt navn',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Prosjekt',
          dataIndex: 'project',
          key: 'project',
        },
        {
          title: 'Avdeling',
          dataIndex: 'department',
          key: 'department',
        },
        {
          title: 'Kontraktsform',
          dataIndex: 'contractType',
          key: 'contractType',
        },
      ],
    }
  },
  methods: {
    editContract(id) {
      this.$router.push({ path: `/HR/settings/contract/${id}` })
    },
    testfn() {
      // add andd then remove an atrribute of test for the last object of positions from each user in the database
      firestore.collection('users').where('status', '==', 'active').get().then(snapshot => {
        snapshot.forEach(doc => {
          const positions = doc.data().positions
          if (!positions) return
          positions.forEach((p, i) => {
            p.test = 'test2'
          })
          // const last = positions[positions.length - 1]
          // if (!last) return
          // // remove the test attribute
          // // delete last.test
          // last.test = 'test2'
          firestore.collection('users').doc(doc.id).update({ positions })
        })
      })
      console.log('done')
    },
  },
  mounted() {
    firestore.collection('settings').doc('companies').collection('companies').get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const company = {
            name: doc.data().displayName,
            id: doc.data().id,
          }
          this.companies.push(company)
        })
        return firestore.collection('settings').doc('projects').collection('projects').get()
      }).then(snapshot => {
        snapshot.forEach(doc => {
          const project = {
            title: doc.data().name,
            id: doc.id,
            subtext: `Status: ${doc.data().status}`,
            company: doc.data().company,
          }
          this.projects.push(project)
        })
        return firestore.collection('settings').doc('contracts').collection('contracts').get()
      }).then(snapshot => {
        snapshot.forEach(doc => {
          const contract = {
            title: doc.data().title,
            project: doc.data().project,
            id: doc.id,
            subtext: `Avdeling: ${doc.data().department}, Stilling: ${doc.data().employmentType}`,
          }
          this.contracts.push(contract)
        })
        return firestore.collection('settings').doc('documents').collection('documents').where('type', '==', 'HR').get()
      }).then(snapshot => {
        snapshot.forEach(doc => {
          const document = {
            title: doc.data().title,
            type: doc.data().type,
            id: doc.id,
          }
          this.documents.push(document)
        })
        console.log(this.documents)
      }).catch(err => {
        console.log(err)
      })
  },
}
</script>
